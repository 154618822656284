import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import {
  Grid,
  Typography,
  ThemeProvider,
  Container,
  Box,
  createMuiTheme,
  CircularProgress
} from "@material-ui/core";

import Item from "./Item";

function Category() {
  let { placeName, catalogId, categoryIndex } = useParams();
  let [category, setCategory] = useState({ items: [] });
  let [catalog, setCatalog] = useState({ name: "" });
  let [allergens, setAllergens] = useState([]);
  let [loading, setLoading] = useState(false);
  const theme = createMuiTheme({ palette: { type: "light" } });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let allergensResponse = await fetch(
          process.env.REACT_APP_API_ALLERGENS_URL
        );
  
        setAllergens(await allergensResponse.json());
  
        let placeUrl = process.env.REACT_APP_API_PLACE_URL.replace(
          "%placeName%",
          placeName
        );
  
        let placeRes = await fetch(placeUrl);
        let placeId = (await placeRes.json()).id;
  
        let url = process.env.REACT_APP_API_CATALOG_URL.replace(
          "%placeId%",
          placeId
        );
  
        let res = await fetch(url);
        let catalogs = await res.json();
        let catalog = catalogs.filter((cat) => cat.id === catalogId)[0];
        setCatalog(catalog);
  
        let category = catalog.categories[categoryIndex];
        setCategory(category);        
        setLoading(false);
      } catch(error) {
        setLoading(false);
      }
    }

    fetchData();
  }, [placeName, catalogId, categoryIndex]);

  if (loading) {
    return(
      <ThemeProvider theme={theme}>
          <Container maxWidth='md'>
              <Box m={2} display="flex" justifyContent="center">
                  <CircularProgress color="black"></CircularProgress>
              </Box>
          </Container>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box m={2}>
            <Typography variant="h4" gutterBottom>
              {catalog.name}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {category.name}
            </Typography>
            <Grid container spacing={2}>
              {category.items.map((item, i) => (
                <Item item={item} allergens={allergens} key={i}></Item>
              ))}
            </Grid>
            {catalog.bottomNotes?.map((note) => {
              return <Typography className="bottom-note" variant="body1">{note}</Typography>;
            })}
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

export default Category;
