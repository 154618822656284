import React, { useEffect, useState} from 'react';
import {  useParams } from "react-router-dom";

import {Card, Grid, Box, CardContent, Typography, CardActionArea, createMuiTheme, ThemeProvider, Container, CircularProgress} from '@material-ui/core';

function Place(){
    let {placeName} = useParams();

    const [place, setPlace] = useState({catalogs:[]});

    const [httpError, setHttpError] = useState(false);

    const [loading, setLoading] = useState(true);

    const theme = createMuiTheme({palette:{type:'light'}});

    useEffect(() => {

        async function fetchData() {

            let placeUrl = process.env.REACT_APP_API_PLACE_URL.replace('%placeName%', placeName);

            try{

                setLoading(true);

                let placeRes = await fetch(placeUrl);

                let placeId = (await placeRes.json()).id;

                let url = process.env.REACT_APP_API_CATALOG_URL.replace('%placeId%', placeId);
    
                let res = await fetch(url);   

                let catalogs = await res.json();

                setPlace({catalogs});

                setLoading(false);

            }catch(error){

                setHttpError(true);

                setLoading(false);

            }

        }

        fetchData();

    }, [placeName]);

    if(loading) {

        return(
            <ThemeProvider theme={theme}>
                <Container maxWidth='md'>
                    <Box m={2} display="flex" justifyContent="center">
                        <CircularProgress color="black"></CircularProgress>
                    </Box>
                </Container>
            </ThemeProvider>
        )

    } else if(httpError || !place.catalogs.length){

        return(
            <ThemeProvider theme={theme}>
                <Container maxWidth='md'>
                    <Box m={2} display="flex" justifyContent="center">
                        <Typography variant='h5'>Nessun Menu Trovato</Typography>
                    </Box>
                </Container>
            </ThemeProvider>
        )

    }else{
        return( 
            <ThemeProvider theme={theme}>
                <Container maxWidth='md'>
                    <Box m={2}>
                        <Grid container spacing={2}>
                            {place.catalogs.map((catalog,i) =>  
                                <Grid item xs={12} key={i}>
                                    <Card>
                                        <CardActionArea href={'/'+placeName+'/'+catalog.id}>
                                            <CardContent>
                                                <Typography variant='h4'>{catalog.name}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </ThemeProvider>
    );

    }

    

}

export default Place;