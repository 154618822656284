import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  createMuiTheme,
  ThemeProvider,
  Container,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress
} from "@material-ui/core";

function Catalog(props) {
  let { placeName, catalogId } = useParams();

  let [catalog, setCatalog] = useState({
    name: "",
    categories: [],
    bottomNotes: [],
  });

  let [loading, setLoading] = useState(false);

  const theme = createMuiTheme({ palette: { type: "light" } });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let placeUrl = process.env.REACT_APP_API_PLACE_URL.replace(
        "%placeName%",
        placeName
      );
      try {        
        let placeRes = await fetch(placeUrl);
        let placeId = (await placeRes.json()).id;

        let url = process.env.REACT_APP_API_CATALOG_URL.replace(
          "%placeId%",
          placeId
        );
        let res = await fetch(url);
        let catalogs = await res.json();
        let catalog = catalogs.find((cat) => cat.id === catalogId);
        setCatalog(catalog);
        setLoading(false);
      } catch(error) {
        setLoading(false)
      }
    }

    fetchData();
  }, [placeName, catalogId]);

  if (loading) {
    return(
      <ThemeProvider theme={theme}>
          <Container maxWidth='md'>
              <Box m={2} display="flex" justifyContent="center">
                  <CircularProgress color="black"></CircularProgress>
              </Box>
          </Container>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box m={2}>
            <Typography variant="h4" gutterBottom>
              {catalog.name}
            </Typography>
            <Grid container spacing={2}>
              {catalog.categories.map((category, i) => (
                <Grid item xs={12} key={i}>
                  <Card>
                    <CardActionArea
                      href={"/" + placeName + "/" + catalog.id + "/" + i}
                    >
                      <CardContent>
                        <Typography variant="h6">{category.name}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {catalog.bottomNotes.map((note) => {
              return <Typography className="bottom-note" variant="body1">{note}</Typography>;
            })}
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

export default Catalog;
