import React from "react";

import { Grid, Card, CardContent, Typography } from "@material-ui/core";

function Item(props) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                {props.item.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="right" gutterBottom>
                {props.item.price} €
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" gutterBottom>
                {props.item.shortDescription}
              </Typography>
              {props.item.allergens.map((allergenId, i) => (
                <img
                  className="allergen-icon"
                  src={
                    props.allergens.find(
                      (allergen) => allergen.id === allergenId
                    )?.imageUrl
                  }
                  alt=""
                  key={i}
                ></img>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Item;
