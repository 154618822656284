import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Place from './components/Place';
import Catalog from './components/Catalog';
import Category from './components/Category';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>                                        
                    <Route path='/:placeName/:catalogId/:categoryIndex'>
                        <Category />
                    </Route>                    
                    <Route path='/:placeName/:catalogId'>
                        <Catalog />
                    </Route>
                    <Route path='/:placeName'>                            
                        <Place />
                    </Route>
                    <Route path='/' render={() => {
                        window.location.href = process.env.REACT_APP_ROOT_REDIRECT;
                        return null
                    }} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
